import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Error from '../../widgets/Error';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Overview from './Overview';
import Sheet from '../../widgets/Sheet';
import Table from '../../widgets/Table';
import useApi from '../../hooks/useApi';
import useBrowse, { BrowseProvider } from '../../hooks/useBrowse';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';
import Post from './Post';
import Dialog from '../../widgets/Dialog';

const List = () => {
    const title = 'Clienti';

    const api = useApi();
    const browse = useBrowse();
    const date = useDate();
    const ui = useUI();

    const [paginator, setPaginator] = useState();
    const [rows, setRows] = useState([]);

    const [query, setQuery] = useState(browse.get('query', ''));
    
    const fetch = () => {
        setPaginator();
        setRows([]);
        
        api.customers.index(browse.queryString()).then(res => {
            setPaginator(res.data);
            setRows(res.data.data);
        });
    };

    useEffect(() => {
        ui.setBreadcrumbs([
            { label: 'Home', path: '/' },
            { label: title },
        ])
    }, []);

    useEffect(() => {
        setQuery(browse.get('query', ''));
        
        fetch();
    }, [browse.params]);

    const handleOverview = (e, customer) => {
        ui.show(<Sheet>
            <Overview _customer={customer} onChanged={fetch} />
        </Sheet>);
    };

    const handlePost = e => {
        ui.show(<Dialog width='48rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <Post onChanged={fetch} />
        </Dialog>);
    };
    
    const handleQueryChanged = e => {
        setQuery(e.currentTarget.value.trimStart());
    };

    const handleQueryCleared = e => {
        setQuery('');
        browse.remove('page');
        browse.remove('sortCol');
        browse.remove('sortDir');
        browse.remove('query', true);
    };

    const handleSubmit = e => {
        browse.remove('page');
        browse.remove('sortCol');
        browse.remove('sortDir');
        browse.put('query', query, true);
    };

    const isResponsive = ui.windowSize.width < 768 === true;

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header className='searchBar'>
            <h1>{title}</h1>
           {/* {isResponsive && <button className='accented' onClick={handlePost}>Aggiungi<Icon name='plus' /></button>}*/}
            <div className='searchBox'>
                <label>
                    <Icon name='magnifying-glass' />
                    <input
                        autoFocus
                        onChange={handleQueryChanged}
                        onKeyDown={e => e.key == 'Enter' && handleSubmit()}
                        placeholder='Cerca'
                        spellCheck='false'
                        value={query} />
                    {query.length > 0 &&
                        <span aria-label='Cancella ricerca' data-action='clear' onClick={handleQueryCleared} type='button'>
                            Cancella
                        </span>
                    }
                </label>
            </div>
            {/*{!isResponsive && <button className='accented' onClick={() => handlePost()}>Aggiungi<Icon name='plus' /></button>}*/}
        </header>
        <div style={{ position: 'relative', height: '50vh' }}>
            {!api.loading && !api.error && <>
                <Table.Header paginator={paginator} />
                <Table loader={api} browse={browse}>
                    <Table.Head browse={browse} columns={[
                        { label: 'Ragione sociale', sortCol: 'title' },
                        { label: 'Residenza / Sede', expanded: true},
                        ...(!isResponsive ? [
                            { label: 'E-mail' },
                            { label: 'Cellulare' },
                            { label: 'Ultima modifica', sortCol: 'last_update' }
                        ] : [])
                    ]} />
                    <Table.Body>
                        {rows.map((row, index) => {
                            const correspondence = row.correspondences.find(correspondence => correspondence.status_correspondence.value == 0);
                            const addressbook = row.address_books.find(addressbook => addressbook.status_addressbook.value == 0);

                           return ( 
                                <Table.Row key={index} onClick={e => handleOverview(e, row)} even={index % 2 !== 0}>
                                    <Table.Cell expanded={isResponsive}>{row.title}</Table.Cell>
                                    <Table.Cell>{correspondence && `${correspondence.geo_location.COUNCIL}, ${correspondence.geo_location.PRVCODE}`}</Table.Cell>
                                    {!isResponsive && <>
                                        <Table.Cell>{addressbook && addressbook.attributables.MAIL}</Table.Cell>
                                        <Table.Cell>{addressbook && addressbook.attributables.CELL}</Table.Cell>
                                        <Table.Cell>{date.locale(row.last_update)}</Table.Cell>
                                    </>}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer browse={browse} paginator={paginator} />
                </Table>
            </>}
            <Loading show={api.loading} />
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </div>
    </>
};

const Index = () => {
    return <>
        <BrowseProvider>
            <List />
        </BrowseProvider>
    </>
};

export default Index;
