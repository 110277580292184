const customers = client => {
    return {
        index: queryString => client.get(`/api/customers${queryString}`),
        create: () => client.get(`/api/customers/create`),
        postPhysical: data => client.post(`/api/customers/physical`, data),
        postLegal: data => client.post(`/api/customers/legal`, data),
        patch: (id, data) => client.post(`/api/customers/${id}/patch`, data),
        edit: id => client.get(`/api/customers/${id}/edit`),
        get: id => client.get(`/api/customers/${id}`),
        /* patch: (id, data) => client.post(`/api/customers/${id}/patch`, data), */
        _logs: {
            attachments: id => client.get(`/api/customers/${id}/logs/attachments`),
            privacyNotes: id => client.get(`/api/customers/${id}/logs/privacy-notes`)
        }
    };
};

export default customers;
