import React, { Fragment, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import Icon from '../../../widgets/Icon';
import useDate from '../../../hooks/useDate';
import useUI from '../../../hooks/useUI';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const AttachmentSendingDetail = ({ envelope, parentDialog }) => {
    const date = useDate();
    const ui = useUI();

    const [numPages, setNumPages] = useState(0);
    const [show, setShow] = useState(false);

    const handleLoadSuccess = document => {
        setNumPages(document.numPages);
    };

    const handlePreview = document => {
        setShow(document.id);
    };

    const loading = (
        <div className='doc-loading' />
    );
    //console.log(envelope);

    const documentWidth = ui.windowSize.width > 768 ? 210 : ui.windowSize.width - 28;
    //const envelop = Object.entries(envelope);
    //console.log(envelop);

    /* const document = document; */
    
    return <>
        <header>
            <span>Dettaglio invio</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <div style={{ display: 'flex', gap: '1rem', flexDirection: ui.windowSize.width < 768 ? 'column' : 'row'  }}>
                {[envelope].map((envelope, index) => {
                    const log = envelope.data;
		    const document = envelope.document;
		    console.log(envelope);

                    return <div key={index} className='log' style={{ minWidth: '32rem' }}>
                        {/* <span className="heading">Anteprima</span> */}
                        <div className='document'>
                            <header>
                                <span className='title'>{document.template?.title || 'Modello sconosciuto'}</span>
                                <span className='pages'><Icon name='file'/>PDF - {numPages} pagin{numPages != 1 ? 'e' : 'a'}</span>
                            </header>
                            <div className='cover'>
                                <Document file={`data:application/pdf;base64, ${document.content}`} onLoadSuccess={handleLoadSuccess} loading={loading}>
                                    <Page pageNumber={1} width={documentWidth} />
                                </Document>
                                <button onClick={() => handlePreview(document)}>
                                    <span>Visualizza</span><Icon name='arrow-up-right-from-square'/>
                                </button>
                            </div>
                        </div>
                        <div className='divider' />
                        <span className="heading">Informazioni</span>
                        <div className='attributes'>
                            <div>
                                <span>Produttore</span>
                                <span className='channel'>
                                    {document.data?.broker ? `${document.data.broker.code} - ${document.data.broker.title}` : 'XX - Sconosciuto'}
                                </span>
                            </div>
                            <div>
                                <span>Data e ora</span>
                                <span></span>
                            </div>
                            <div>
                                <span>Canale</span>
                                <span>{log.channel}</span>
                            </div>
                            {log.channel == 'email' && <>
                                <div>
                                    <span>E-mail</span>
                                    <span>{log.email}</span>
                                </div>
                            </>}
                            {log.channel == 'sms' && <>
                                <div>
                                    <span>Cellulare</span>
                                    <span>{log.cell}</span>
                                </div>
                            </>}
                            <div>
                                <span>Rif. interno</span>
                                <span>{document.data?.internal_ref || '-'}</span>
                            </div>
                            <div>
                                <span>Rif. prodotto</span>
                                <span>{document.data?.product_ref || '-'}</span>
                            </div>
                        </div>
                        {show === document.id && <>
                            <div className='preview'>
                                <header>
                                    <span className='pages'><Icon name='file'/>PDF - {numPages} pagin{numPages != 1 ? 'e' : 'a'}</span>
                                    <span className='title'>{document.data?.template?.title || 'Modello sconosciuto'}</span>
                                    <button onClick={() => setShow(false)}>
                                        <span>Chiudi</span>
                                        <Icon name='times'/>
                                    </button>
                                </header>
                                <main>
                                    <Document file={`data:application/pdf;base64, ${document.content}`} onLoadSuccess={handleLoadSuccess} loading={loading}>
                                        {Array(numPages).fill(null).map((el, index) => {
                                            const width = ui.windowSize.width > 768 ? 800 : ui.windowSize.width - 40;
                                            return (
                                                <Page key={index} pageNumber={index + 1} width={width} />
                                            );
                                        })}
                                    </Document>
                                </main>
                            </div>
                        </>}
                    </div>
                })}
            </div>
        </main>
    </>
};

export default AttachmentSendingDetail;
