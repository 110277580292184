import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Error from '../../widgets/Error';
import Icon from '../../widgets/Icon';
import Loading from '../../widgets/Loading';
import Overview from './Overview';
import Sheet from '../../widgets/Sheet';
import Table from '../../widgets/Table';
import UserRow from '../../widgets/UserRow';
import useApi from '../../hooks/useApi';
import useBrowse, { BrowseProvider } from '../../hooks/useBrowse';
import useDate from '../../hooks/useDate';
import useUI from '../../hooks/useUI';

const List = () => {
    const title = 'Produttori';

    const api = useApi();
    const browse = useBrowse();
    const date = useDate();
    const ui = useUI();

    const [paginator, setPaginator] = useState();
    const [rows, setRows] = useState([]);

    const [query, setQuery] = useState(browse.get('query', ''));
    
    const fetch = () => {
        setPaginator();
        setRows([]);
        
        api.brokers.index(browse.queryString()).then(res => {
            setPaginator(res.data);
            setRows(res.data.data);
        });
    };

    useEffect(() => {
        ui.setBreadcrumbs([
            { label: 'Home', path: '/' },
            { label: title },
        ])
    }, []);

    useEffect(() => {
        setQuery(browse.get('query', ''));
        
        fetch();
    }, [browse.params]);

    const handleOverview = (e, id) => {
        ui.show(<Sheet>
            <Overview id={id} />
        </Sheet>);
    };

    const handlePost = e => {
        alert('[DEBUG]: handlePost');
    };
    
    const handleQueryChanged = e => {
        setQuery(e.currentTarget.value.trimStart());
    };

    const handleQueryCleared = e => {
        setQuery('');
        browse.remove('page');
        browse.remove('sortCol');
        browse.remove('sortDir');
        browse.remove('query', true);
    };

    const handleSubmit = e => {
        browse.remove('page');
        browse.remove('sortCol');
        browse.remove('sortDir');
        browse.put('query', query, true);
    };

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header className='searchBar'>
            <h1>{title}</h1>
            <div className='searchBox'>
                <label>
                    <Icon name='magnifying-glass' />
                    <input
                        autoFocus
                        onChange={handleQueryChanged}
                        onKeyDown={e => e.key == 'Enter' && handleSubmit()}
                        placeholder='Cerca'
                        spellCheck='false'
                        value={query} />
                    {query.length > 0 &&
                        <span aria-label='Cancella ricerca' data-action='clear' onClick={handleQueryCleared} type='button'>
                            Cancella
                        </span>
                    }
                </label>
            </div>
        </header>
        <div style={{ position: 'relative', height: '50vh' }}>
            {!api.loading && !api.error && <>
                <Table.Header paginator={paginator} />
                <Table loader={api} browse={browse}>
                    <Table.Head browse={browse} columns={[
                        { label: 'Produttore', sortCol: 'title' },
                        { label: 'Codice' },
                        { label: 'Allegati', expanded: true}
                    ]} />
                    <Table.Body>
                        {rows.map((row, index) =>
                            <Table.Row key={index} onClick={e => handleOverview(e, row.id)} even={index % 2 !== 0}>
                                <Table.Cell>
                                    <UserRow name={row.title} />
                                </Table.Cell>
                                <Table.Cell>{row.attributables.BUID}</Table.Cell>
                                <Table.Cell>
                                    {row.attachments_count 
                                        ? <>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
                                                <Icon name='paperclip' style={{ minWidth: '2rem', textAlign: 'center' }} />
                                                <span style={{ fontWeight: 'var(--fontMedium)' }}>{row.attachments_count}</span>
                                            </div>
                                        </>
                                        : <>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: 'var(--colorNegative' }}>
                                                <Icon name='triangle-exclamation' style={{ minWidth: '2rem', textAlign: 'center' }} />
                                                <span style={{ fontWeight: 'var(--fontMedium)' }}>{row.attachments_count}</span>
                                            </div>
                                        </>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer browse={browse} paginator={paginator} />
                </Table>
            </>}
            <Loading show={api.loading} />
            {api.error && <Error text={api.error.message} onReload={fetch}></Error>}
        </div>
    </>
};

const Index = () => {
    return <>
        <BrowseProvider>
            <List />
        </BrowseProvider>
    </>
};

export default Index;
