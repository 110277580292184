import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import Alert from '../../../widgets/Alert';
import AttachmentSendingDetail from './AttachmentSendingDetail';
import Dialog from '../../../widgets/Dialog';
import Icon from '../../../widgets/Icon';
import Loading from '../../../widgets/Loading';
import Post from '../../Envelopes/Attachments/Post';
import useApi from '../../../hooks/useApi';
import useDate from '../../../hooks/useDate';
import useUI from '../../../hooks/useUI';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Envelopes = ({ customer, envelope_id, parentDialog }) => {
    const api = useApi();
    const date = useDate();
    const ui = useUI();
    console.log("component eid:", envelope_id);

    const [envelopes, setEnvelopes] = useState([]);

	const realEnvelopes = envelope_id ? envelopes.filter(env => {
		return env.document.envelope_id == envelope_id;
	}) : envelopes;

    useEffect(() => {
        api.customers._logs.attachments(customer.id).then(res => {
            setEnvelopes(res.data);
        })
    }, []);

    const handleDetail = envelope => {
        ui.show(<Dialog height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <AttachmentSendingDetail envelope={envelope} />
        </Dialog>);
    };

    return <>
       <header>
            <span>Allegati inviati</span>
            <div className='actions'>
                <button onClick={e => parentDialog.dismiss()}>
                    <Icon name='times'/>
                </button>
            </div>
        </header>
        <main>
            <div data-component='customerEnvelopes'>
                {realEnvelopes.length > 0 && <>
                    <div className='envelope'>
                        <span className='date'>Data e ora</span>
                        <span className='title'>Tipo</span>
                        <span className='channel'>Produttore</span>
                    </div>
                    {realEnvelopes.map((envelope, index) => {
                        const brokerCode = envelope.document.data.broker.code;
                        {/*let title = envelope.document.map(doc => doc.data?.template?.title || 'n/d').join(', ');*/}
                        let title = envelope.document.template.title;
                        title = title.replaceAll('Allegato ', '');
                        return (
                            <div key={index} role='button' className='envelope' onClick={() => handleDetail(envelope)}>
                                <span className='date'>{date.locale(envelope.created_at)}</span>
                                <span className='title'>{title}</span>
                                <span className='channel'>{brokerCode || 'XX'}</span>
                            </div>
                        )
                    })}
                </>}
                <Alert mode='info' show={!api.loading && !envelopes.length}>
                    <Icon name='circle-info'/>
                    <span>Non ci sono dati da mostrare</span>
                </Alert>
            </div>
        </main>
        <Loading show={api.loading && !api.error} opaque={!envelopes.length} />
    </>
};

const Attachments = ({ customer, onChanged, widget = false }) => {
    const date = useDate();
    const ui = useUI();

    const handleDetail = envelope => {
        ui.show(<Dialog height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <AttachmentSendingDetail envelope={envelope} />
        </Dialog>);
    };

    const handleEnvelopePost = (e, customer) => {
        ui.show(<Dialog width='36rem' height={ui.windowSize.width < 768 ? '100vh' : 'auto'}>
            <Post customer={customer} onChanged={onChanged} />
        </Dialog>);
    };

    const handleEnvelopes = (e, envelope_id) => {
        console.log('mid eid:', envelope_id);
        ui.show(<Dialog width='48rem'>
            <Envelopes customer={customer} envelope_id={envelope_id}/>
        </Dialog>);
    };

    {/*if (customer.attachmentsEnvelopes == null) {
        return <>
            <header>
                <span className='title'>Allegati IVASS</span>
                <span role='button' className='count' onClick={e => handleEnvelopes(e, false)}>Visualizza tutti</span>
            </header>
            <button className='accented-2' onClick={e => handleEnvelopePost(e, customer)}>
            <span>Invia allegati IVASS</span>
            <Icon name='paper-plane' />
        </button>
        </>
    }*/}

        let attachments_array = customer.attachments;
        let envelope_arr = [];

        console.log(attachments_array);
		
		let limit = attachments_array.length > 5 ? 4 : attachments_array.length;

        for(let i=0; i<attachments_array.length; i++){
                console.log('stringa ID', attachments_array[i].document.envelope_id);
                if(typeof(envelope_arr["envelope_"+attachments_array[i].document.envelope_id.toString()]) == 'undefined'){
                        envelope_arr["envelope_" + attachments_array[i].document.envelope_id.toString()] = [];
                        envelope_arr["envelope_" + attachments_array[i].document.envelope_id.toString()]["title"] = [];
                        envelope_arr["envelope_" + attachments_array[i].document.envelope_id.toString()]["entity"] = [];
                        envelope_arr["envelope_" + attachments_array[i].document.envelope_id.toString()]["created_at"] = '';
                }
         envelope_arr["envelope_" + attachments_array[i].document.envelope_id]["title"].push(attachments_array[i].document.template.title.replace('Allegato ', ''));
         envelope_arr["envelope_" + attachments_array[i].document.envelope_id]["entity"].push(attachments_array[i]);
         envelope_arr["envelope_" + attachments_array[i].document.envelope_id]["created_at"] = attachments_array[i].created_at;
        }

        let envelope_list = [];

        for(let i in envelope_arr){
                let envelopeId = parseInt(i.replace('envelope_', ''));
                console.log('parsedEnvINT', envelopeId);
                console.log('elm', envelope_arr);
                console.log('iende', envelopeId);
                console.log('map', envelope_arr[i]["title"].slice(0,3).join(', '));
                //envelope_list.push( <div key={i} role='button' className='userRow' onClick={e => handleDetail(envelope_arr[i]["entity"])}><div className='title'>{envelope_arr[i]["title"].slice(0,3).join(', ')}</div><div className='date'>{date.locale(envelope_arr[i]["created_at"])}</div></div>);
                envelope_list.push( <div key={i} role='button' onClick={e => handleEnvelopes(e, envelopeId)} className='envelope'><div className='title'>{envelope_arr[i]["title"].slice(0,3).join(', ')}</div><div className='date'>{date.locale(envelope_arr[i]["created_at"])}</div></div>);
        }

        console.log(envelope_arr);
        console.log(envelope_arr.length);
                let html = '';
    return <>
        <header>
            <span className='title'>Allegati IVASS</span>
            <span role='button' className='count' onClick={e => handleEnvelopes(e, false)}>Visualizza tutti</span>
        </header>
        <div className='attachmentsEnvelopesList'>
            {envelope_list}
            <Alert show={!customer.attachments.length} mode='accented' >
                <span>Gli allegati IVASS inviati al cliente verranno visualizzati qui</span>
            </Alert>
        </div>
        <button className='accented-2' onClick={e => handleEnvelopePost(e, customer)}>
            <span>Invia allegati IVASS</span>
            <Icon name='paper-plane' />
        </button>
        {/* {widget && <footer className='lisalexPowered'>
            <span>Powered by</span><img src='/assets/logo.svg' alt='Lisalex' />
        </footer>} */}
    </>
}

export default Attachments;
